@font-face {
  font-family: "lato";
  src:
    local("lato-regular"),
    url(./assets/fonts/Lato-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "lato";
  src:
    local("lato-bold"),
    url(./assets/fonts/Lato-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "lato";
  src:
    local("lato-regular-italic"),
    url(./assets/fonts/Lato-RegularItalic.ttf) format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "lato";
  src:
    local("lato-regular-bold-italic"),
    url(./assets/fonts/Lato-BoldItalic.ttf) format("truetype");
  font-style: italic;
  font-weight: bold;
}

body {
  margin: 0;
  font-family: lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide number type input arrows for Chrome, Safari, Edge, Opera */
.hide-number-input-arrows input::-webkit-outer-spin-button,
.hide-number-input-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide number type input arrows for Firefox */
.hide-number-input-arrows input[type="number"] {
  -moz-appearance: textfield;
}
